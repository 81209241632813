<template>
	<div class="container">
		<main style="
        height: calc(100% - 80px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      ">
			<!-- 正常登录 -->
			<div class="login-form">
				<el-form :model="loginForm" :rules="rules" ref="loginForm" class="demo-loginForm">
					<p>账号登录</p>
					<el-form-item prop="username">
						<el-input v-model="loginForm.username" placeholder="用户名/邮箱"></el-input>
					</el-form-item>
					<el-form-item prop="userpass">
						<el-input type="password" v-model="loginForm.userpass" placeholder="密码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button :loading="isLoding" class="submit-btn" type="primary"
							@click="submitForm('loginForm')">登录</el-button>
					</el-form-item>
					<!-- <div class="forget-pass" @click="forgetPass">
								忘记密码？
							</div> -->
				</el-form>
			</div>
		</main>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	export default {
		data() {
			var userPassWord = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.resetForm.password) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			var checkEmail = (rule, value, callback) => {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
				if (!value) {
					return callback(new Error("邮箱不能为空"));
				}
				setTimeout(() => {
					if (mailReg.test(value)) {
						callback();
					} else {
						callback(new Error("请输入正确的邮箱格式"));
					}
				}, 100);
			};
			return {
				show: true, // 初始启用按钮
				count: "", // 初始化次数
				timer: null,
				isLoding: false,
				loginForm: {
					username: "",
					userpass: "",
				},
				verificationForm: {
					email: "",
					emailpass: "",
				},
				resetForm: {
					password: "",
					andpassword: "",
				},
				rules: {
					password: [{
							required: true,
							message: "密码不可为空",
							trigger: "blur",
						},
						{
							min: 3,
							max: 5,
							message: "长度在 3 到 5 个字符",
							trigger: "blur",
						},
					],
					andpassword: [{
							required: true,
							message: "密码不可为空",
							trigger: "blur",
						},
						{
							validator: userPassWord,
							trigger: "blur",
						},
					],
					username: [{
						required: true,
						message: "请输入用户名",
						trigger: "blur",
					}, ],
					userpass: [{
						required: true,
						message: "密码不可为空",
						trigger: "blur",
					}, ],
					email: [{
						validator: checkEmail,
						trigger: "blur",
					}, ],
					emailpass: [{
						required: true,
						message: "验证码不可为空",
						trigger: "blur",
					}, ],
				},
			};
		},
		created() {
			var _self = this;
			// console.log(localStorage.getItem("rememberToken"));
			if (Cookies.get('rememberToken')) {
				// this.$router.push(localStorage.getItem('routeUrl'));
				this.$router.push('/statistics');
			}
			document.onkeydown = function() {
				var key = window.event.keyCode;
				if (key == 13) {
					if (!_self.isLoding) {
						_self.submitForm("loginForm");
					}
				}
			};
		},
		methods: {
			submitForm: function(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.isLoding = true;
						console.log(valid);
						this.$post("login", {
							username: this.loginForm.username,
							password: this.loginForm.userpass,
						}).then((res) => {
							console.log(res);
							if (res.data.status == 200) {
								Cookies.set("remember_userinfo", JSON.stringify(res.data.data));
								// localStorage.setItem(
								// 	"rememberToken",
								// 	res.data.data.remember_token
								// );
								Cookies.set('rememberToken', res.data.data.remember_token)
								// this.$axios
								//   .get(this.$store.state.httpUrl + "access/route", {
								//     headers: {
								//       "Remember-Token": res.data.data.remember_token,
								//     },
								//     params: {},
								//   })
								//   .then((resd) => {
								//     console.log(resd);
								//     let list = JSON.stringify(resd.data.data.route);
								//     localStorage.setItem(
								//       "routeUrl",
								//       resd.data.data.route[0].module_route
								//     );
								//     localStorage.setItem("quanxian", list);
								//     location.reload(true);
								//   });
								location.reload(true);
							} else {
								this.isLoding = false;
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							this.isLoding = false;
						});
						// localStorage.setItem("rememberToken", "123");
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.container {
		height: 100vh;
		min-width: 500px;
		background: url("../../../static/images/bz3.jpg") no-repeat top center;
		background-size: cover;
		background-attachment: fixed;

		.login-form,
		.verification-email {
			background-color: #eeeef3;
			padding: 0 20px;
			border-radius: 10px;
			width: 300px;

			.demo-loginForm {
				p {
					font-weight: 700;
					text-align: center;
				}
			}

			.submit-btn {
				margin-top: 20px;
				width: 100%;
			}

			.forget-pass {
				cursor: pointer;
				color: #999;
				margin-bottom: 20px;
				text-align: right;
				font-size: 13px;
			}
		}
	}
</style>
